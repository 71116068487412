<template>
    <div style="padding: 15px">
    <q-list bordered separator>
        <q-item >
            <q-item-section>{{ $t('login.username') }}</q-item-section>
            <q-item-section>{{ user.username }}</q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('login.remark') }}</q-item-section>
            <q-item-section>{{ user.last_name }}</q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('app.farm') }}</q-item-section>
            <q-item-section>{{ farm.name }} </q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('app.tank') }}</q-item-section>
            <q-item-section>
            <div v-if="tank_name_readonly">
                {{ tank_name }}
            </div>
            <q-input v-else outlined v-model="tank_name" />
            </q-item-section>
            <q-item-section>
                <div >
                    <q-btn v-if="tank_name_readonly" outline :label="$t('app.edit')" color="secondary" v-on:click="tank_name_readonly = false" />
                    <q-btn v-else outline :label="$t('form.submit')" color="secondary" v-on:click="update_info('name', tank_name)" />
                </div>
            </q-item-section>
        </q-item>
    </q-list>

    <q-list bordered separator style="margin-top: 30px">
        <q-item class="row justify-end">
            <q-item-section>{{ $t('app.tank') }}: {{ tank.name }}</q-item-section>
            <q-item-section>
                <div style="text-align: right; color: red">
                    <span v-if="sync_tank_expected_size > 0">{{ $t('app.operating') }} ({{ sync_tank_expected_retry }})...</span>
                    <q-btn size="sm" style="margin-left: 5px;" outline :label="$t('app.check_new')" type="submit" color="secondary" v-on:click="retrieveTank()" />
                </div>
            </q-item-section>
        </q-item>
        <q-item v-ripple>
            <q-item-section>{{ $t('app.circulation_state') }}: </q-item-section>
            <q-item-section class="row items-start">
                <q-toggle
                    class="col-3"
                    size="xl"
                    :false-value="'OFF'"
                    :true-value="'ON'"
                    color="green"
                    :label="tank.info['circulation_state']"
                    left-label
                    :disable="tank_op_disable || (!online)"
                    v-model="tank.info['circulation_state']"
                    @click="powerSwitchTank()"
                />
            </q-item-section>
        </q-item>
        <q-item v-ripple>
            <q-item-section>{{ $t('app.temp_sw') }}: </q-item-section>
            <q-item-section class="row items-end">
                <div v-if="temp_sw_readonly">{{ temp_sw }} °C</div>
                <q-input v-else type="number" min="60" max="80" outlined v-model="temp_sw" />
            </q-item-section>
            <q-item-section class="row items-start">
                <div >
                    <q-btn v-if="temp_sw_readonly" outline :label="$t('app.edit')" color="secondary" v-on:click="temp_sw_readonly = false" />
                    <q-btn v-else outline :label="$t('form.submit')" color="secondary" v-on:click="setTempSw(temp_sw)" />
                </div>
            </q-item-section>
        </q-item>
        <q-item v-ripple v-for="key in ['temp_sensors', 'liquid_level', 'version']" :key="key">
            <q-item-section>{{ $t('app.'+key) }}: </q-item-section>
            <q-item-section>{{ tank.info[key] }}</q-item-section>
        </q-item>
    </q-list>
   
   <div  style="margin-top:20px; font-size:20px">{{ $t('app.miner') }}</div>
   <q-card class="info-card" style="border-style: solid">
    <div class="row">
        <div class="col-10 row">
            <div class="col-4" v-for="key in miners_key_list" :key="key" style="border-style: groove groove groove groove">
                <q-item-label style="font-size:30px; text-align: center;">{{ key }}</q-item-label>
                <div style="text-align: center;">
                <q-toggle
                    size="xl"
                    :false-value="'OFF'"
                    :true-value="'ON'"
                    color="green"
                    v-model="miners[key]"
                    :disable="miners_disable[key] || (!online)"
                    @click="powerSwitch(key)"
                />
                </div>
            </div>
        </div>
        <div class="col-2" style="border-style: hidden hidden hidden double">
        </div>
    </div>
   </q-card>
    <div style="margin-top:10px; font-size:20px">{{ $t('app.miner_switch_desc') }}</div>
    
    <div class="row justify-center" style="padding: 15px; margin-top:20px;">
        <q-btn style="margin-left: 5px" outline :label="$t('app.delete') + ' ' + $t('app.tank')" type="submit" color="negative" v-on:click="deletePage = true" />
    </div>
    </div>

    <q-dialog
      v-model="deletePage"
      persistent
      :maximized="true"
    >
      <q-card style="backgroundColor: rgb(245, 245, 245)">
        <q-card-section>
            <q-list bordered separator>
            <q-item >
                <q-item-section>{{ $t('login.user_id') }}</q-item-section>
                <q-item-section>{{ user.id }}</q-item-section>
            </q-item>
            <q-item >
                <q-item-section>{{ $t('login.username') }}</q-item-section>
                <q-item-section>{{ user.username }}</q-item-section>
            </q-item>
            <q-item >
                <q-item-section>{{ $t('login.remark') }}</q-item-section>
                <q-item-section>{{ user.last_name }}</q-item-section>
            </q-item>
            <q-item >
                <q-item-section>{{ $t('app.farm') }}</q-item-section>
                <q-item-section>{{ farm.name }} </q-item-section>
            </q-item>
        </q-list>
    
        </q-card-section>

        <q-card-section>
        <div>{{ $t('app.delete') + ' ' + $t('app.tank') }}:  {{ tank.name }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none justify-center" style="color: red">
           {{ $t('app.delete_confirm_message') }}
        </q-card-section>
        <q-card-section class="q-pt-none justify-center" style="color: red">
           {{ $t('app.delete_type_message') }}
        </q-card-section>
        <q-card-section class="q-pt-none justify-center" style="color: red" >
            <q-input ref="delete_input" outlined v-model="deleteComfirm" :dense="dense" />
        </q-card-section>
        <q-card-section>
        <div class="justify-center">
            <q-btn size="sm" outline :label="$t('app.go_back')" type="submit" color="secondary" @click="deletePage = false"/>
            <q-btn size="sm" style="margin-left: 5px" outline :label="$t('app.delete')" type="submit" color="negative" v-on:click="deleteTank()" />
        </div>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>


export default {
    data () {
        return {
            user_id: this.$route.params.user_id,
            farm_id: this.$route.params.farm_id,
            tank_id: this.$route.params.tank_id,

            tank: {info: {}},
            
            miners: {},
            miners_disable: {},

            tank_op_disable: false,

            tank_name_readonly: true,
            tank_name: '',

            farm: {},
            user: {},

            deleteComfirm: '',
            deletePage: false,

            temp_sw_readonly: true,
            temp_sw: 72,

            sync_tank_expected: [],
            sync_tank_expected_retry: 0
        }
    },
    created () {
        this.retrieveTank();
    
        this.retrieve_user_info();
        this.retrieve_farm_info();
    },
    
    computed: {
        sync_tank_expected_size() {
            return this.sync_tank_expected.length;
        },
        miners_key_list() {
            if (this.miners) {
                return [6, 5, 4, 1, 2, 3];
            }
            else
            {
                return [];
            }
        },
        online() {
            if (this.tank.info['CONN_STATUS'] == 'ONLINE') {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        sync_tank_expected_size(newSize, oldSize) {
            if (oldSize == 0 && newSize > 0) {
                this.retrieveExpectedTankAsync();
            }
        }
    },
    methods: {
        async setTankData(data) {
            this.tank = data;
            if (data.info.temp_sw) {
                this.temp_sw = data.info.temp_sw;
            }
            this.miners = data.info.machine_state;
            this.tank_name = data['name'];
        },
        async retrieveExpectedTank() {
            this.sync_tank_expected_retry += 1;
            let response = await this.$fetch('/api/v1/user/'+this.user_id +'/tank/'+this.tank_id);
            if (response.status == 200) {
                let data = await response.json();

                let sync_tank_expected_remain = [];
                for (let func of this.sync_tank_expected) {
                    try {
                        if (!func(data)) {
                            sync_tank_expected_remain.push(func);
                        }
                    } catch (e) {
                        console.log(e);
                        sync_tank_expected_remain.push(func);
                    }
                }

                if (sync_tank_expected_remain.length > 0) {
                    this.sync_tank_expected = sync_tank_expected_remain;
                } else {
                    this.setTankData(data);
                    this.sync_tank_expected = [];
                    this.sync_tank_expected_retry = 0;
                    return;
                }
            }

            let that = this;
            setTimeout(() => {
                that.retrieveExpectedTank(); 
            }, 1000);
        },
        async retrieveExpectedTankAsync() {
            let that = this;
            setTimeout(() => {
                that.retrieveExpectedTank(); 
            }, 1000);
        },
        async setTempSw(value) {
            let response = await this.$fetch('/api/v1/user/'+this.user_id+'/tank/'+this.tank_id+'/op', {
                body: JSON.stringify({'action': 'set_param', 'name': 'temp_sw', 'value': value}),
                method: 'POST'
            });

            if (response.status == 200) {
                await response.json();
                this.temp_sw_readonly = true;

                let that = this;
                this.sync_tank_expected.push(
                    (data) => {
                        if (data.info.temp_sw == value) {
                            that.tank.info.temp_sw = value;
                            return true;
                        } else {
                            return false;
                        }
                    }
                )
            } else {
                throw "response.status: " + response.status;
            }
        },
        async retrieveTank () {
            let response = await this.$fetch('/api/v1/user/'+this.user_id +'/tank/'+this.tank_id);
            if (response.status == 200) {
                let data = await response.json();
                this.setTankData(data);
            }
        },
        async powerSwitch(miner_id) {
            if (!this.online) {
                return;
            }

            if (this.miners_disable[miner_id] == true) {
                return;
            }

            this.miners_disable[miner_id] = true;
            let action = 'power_off';
            if (this.miners[miner_id] == 'ON') {
                action = 'power_on';
            }
            let response = await this.$fetch('/api/v1/user/'+this.user_id+'/tank/'+this.tank_id+'/miner/'+miner_id+'/op', {
                body: JSON.stringify({'action': action}),
                method: 'POST'
            });

            if (response.status == 200) {
                await response.json();
                let result = "OFF";
                if (action == 'power_on') {
                    result = 'ON';
                }

                let that = this;
                this.sync_tank_expected.push(
                    (data) => {
                        if (data.info.machine_state[miner_id] == result) {
                            that.tank.info.machine_state[miner_id] = result;
                            that.miners_disable[miner_id] = false;
                            return true;
                        } else {
                            return false;
                        }
                    }
                )
            } else {
                throw "response.status: " + response.status;
            }
        },
        async powerSwitchTank() {
            if (!this.online) {
                return;
            }

            if (this.tank_op_disable == true) {
                return;
            }

            this.tank_op_disable = true;
            console.log(this.tank.info['circulation_state']);

            let action = 'power_off';
            if (this.tank.info['circulation_state'] == 'ON') {
                action = 'power_on'
            }

            let response = await this.$fetch('/api/v1/user/'+this.user_id+'/tank/'+this.tank_id+'/op', {
                body: JSON.stringify({'action': action}),
                method: 'POST'
            });

            if (response.status == 200) {
                await response.json();

                let result = "OFF";
                if (action == 'power_on') {
                    result = 'ON';
                }

                let that = this;
                this.sync_tank_expected.push(
                    (data) => {
                        if (data.info.circulation_state == result) {
                            that.tank.info.circulation_state = result;
                            that.tank_op_disable = false;
                            return true;
                        } else {
                            return false;
                        }
                    }
                )
            } else {
                throw "response.status: " + response.status;
            }

        },
        async retrieve_user_info() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id, {
                method: 'get',
            });
            let data = await response.json();
            this.user = data;
        },
        async retrieve_farm_info() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id+'/farm/'+this.farm_id, {
                method: 'get',
            });
            let data = await response.json();
            this.farm = data;
            
        },
        async deleteTank() {
            console.log("delete");
            if (this.deleteComfirm == 'DELETE') {
                let response = await this.$fetch('/api/v1/user/'+this.user_id +'/tank/'+this.tank_id, {
                    method: 'delete',
                });
                if (response.status == 200) {
                    this.deletePage = false;
                    this.$router.go(-1);
                }
            }
            else {
                this.$refs.delete_input.focus();
            }
        },
        async update_info(key, value) {
            let data = {};
            data[key] = value;
            try {
                let response = await this.$fetch('/api/v1/user/'+this.user_id+'/tank/'+this.tank_id, {
                    body: JSON.stringify(data),
                    method: 'PUT'
                });
                if (response.status == 200) {
                    let data = await response.json();
                    this.setTankData(data);
                    this.tank_name_readonly = true;
                } else {
                    throw "response.status: " + response.status;
                }
            } catch (e) {
                this.alert([this.$t('error.retry')]);
            }
        },
    }
}
</script>